import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { RecentOrPopularHotelsComponent as RecentOrPopularHotelsComponentTypes } from '@AuroraTypes';
import { Hotels } from '@Pages/home/Hotels/Hotels';

export const RecentOrPopularHotelsComponent: React.FC<
  Omit<RecentOrPopularHotelsComponentTypes, 'type'>
> = ({ recentOrPopularHotels: { popularHotels } }) => (
  <Hotels
    sx={{
      '.hotelTitleWrapper': {
        height: '2lh',
      },
      '.hotelTitle': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        '-webkit-box-orient': 'vertical',
      } as SxStyleProp,
    }}
    items={popularHotels}
  />
);
